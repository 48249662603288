<template>
  <a-modal
    id="add-translation-modal"
    :visible="isTranslation"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="
      (Object.keys(similarSteps).length === 0 ? 'Add' : 'Update') +
      ' Translation'
    "
    width="60vw"
    @cancel="cancelTranslation"
  >
    <AddTranslation />
  </a-modal>

  <a-modal
    id="similar-steps-modal"
    :visible="showSimilarStepsModal"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="(!isTranslationEdit ? 'Add' : 'Update') + ' Similar Steps'"
    width="60vw"
  >
    <SimilarSteps />
  </a-modal>

  <a-modal
    id="projector-region-modal"
    :visible="showProjectorRegionModal"
    centered
    destroy-on-close
    :closable="true"
    :footer="null"
    :title="'IoT Plugins'"
    width="60vw"
    @cancel="closeProjectorModal"
  >
    <Devices />
  </a-modal>

  <a-modal
    id="step-time-modal"
    :visible="showStepTimeModal"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="`${isStepTimeExist ? 'Update' : 'Add'} Standard Time`"
    width="60vw"
  >
    <StepTime />
  </a-modal>

  <a-modal
    id="task-parameters-modal"
    :visible="showTaskParamsModal"
    centered
    width="50vw"
    destroy-on-close
    :closable="false"
    title="Task Parameters"
    :footer="null"
  >
    <TaskParameters />
  </a-modal>

  <a-modal
    id="mark-static-objects-modal"
    v-model:visible="showRegionDefinitionModal"
    centered
    width="80vw"
    destroy-on-close
    title="Define Regions / Static Objects"
    :closable="false"
    :footer="null"
  >
    <RegionDefinition />
  </a-modal>

  <a-modal
    id="upload-media-modal"
    v-model:visible="showUploadMediaModal"
    centered
    width="60vw"
    destroy-on-close
    title="Upload Step Media"
    :closable="false"
    :footer="null"
  >
    <UploadStepMedia />
  </a-modal>

  <!-- <a-modal
    v-model:visible="showAnnotationObjectsModal"
    centered
    width="60vw"
    :closable="false"
    destroy-on-close
    title="Add Annotation Object(s)"
    :footer="null"
  >
    <ObjectAnnotation />
  </a-modal>  -->

  <a-modal
    id="model-versions-modal"
    v-model:visible="showModelVersionsModal"
    centered
    width="60vw"
    destroy-on-close
    title="Model Versions"
    :footer="null"
    @cancel="
      showModal({
        modalType: types.SHOW_MODEL_VERSIONS_MODAL,
        value: false,
      })
    "
  >
    <ModelVersions />
  </a-modal>

  <a-modal
    id="add-tag-modal"
    v-model:visible="orgTagsModal"
    title="Tags"
    centered
    :footer="null"
  >
    <template #closeIcon>
      <close-outlined id="add-tag-modal-close-btn" />
    </template>
    <a-input-search
      id="tag-name-input"
      v-model:value="tagName"
      name="tagName"
      placeholder="Tag Name"
      @search="handleAddOrgTag"
    >
      <template #enterButton>
        <a-button
          id="add-tag-btn"
          type="primary"
          :disabled="!tagName"
          :loading="isCreatingObject"
        >
          <plus-outlined />
          Add
        </a-button>
      </template>
    </a-input-search>

    <div class="objects-container">
      <a-list
        id="tags-list"
        size="small"
        :loading="isLoadingTag"
        item-layout="horizontal"
        :data-source="orgTags"
      >
        <template #renderItem="{ item, index: tagIndex }">
          <a-list-item>
            <a-list-item-meta
              :id="'tag-' + tagIndex"
              :title="item.tag_name"
              :description="item.descript"
            />
          </a-list-item>
        </template>
      </a-list>
    </div>
  </a-modal>

  <a-row :gutter="[8, 8]" class="p-4 h-100" style="position: relative">
    <a-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      class="d-flex justify-content-end"
      style="
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 1;
        padding: 8px;
      "
    >
      <a-space>
        <a-button id="add-tags-button" type="primary" @click="openTagsModal">
          <template #icon>
            <plus-outlined />
          </template>
          Add Tags
        </a-button>
      </a-space>
    </a-col>

    <a-col :xs="24" :sm="24" :md="24" :lg="12" class="d-flex flex-column mt-5">
      <AddNewTask @onCancelEditTask="resetProcessState" />
    </a-col>

    <a-col :xs="24" :sm="24" :md="24" :lg="12" class="d-flex flex-column mt-5">
      <TaskList />
    </a-col>
  </a-row>
</template>

<script>
import TaskList from './Task/TaskList.vue';
import AddNewTask from './Task/AddNewTask.vue';
import AddTranslation from './Translation/AddTranslation.vue';
import SimilarSteps from './SimilarSteps/SimilarSteps.vue';
import Devices from './ProjectorRegions/Devices.vue';
import StepTime from './StepTime/StepTime.vue';
import TaskParameters from './TaskParameters/TaskParameters.vue';
import RegionDefinition from './RegionDefinition/RegionDefinition.vue';
import UploadStepMedia from './UploadStepMedia/UploadStepMedia.vue';
import ModelVersions from './ModelVersions/ModelVersions.vue';
import { mapActions, mapGetters } from 'vuex';
import types from 'src/store/mutation-types';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons-vue';
import httpClient from 'src/service/httpClient.js';

export default {
  components: {
    TaskParameters,
    StepTime,
    TaskList,
    AddNewTask,
    SimilarSteps,
    AddTranslation,
    RegionDefinition,
    UploadStepMedia,
    ModelVersions,
    Devices,
    PlusOutlined,
    CloseOutlined,
  },

  data() {
    return {
      showPolicyModal: false,
      types,
      orgTagsModal: false,
      isLoadingTag: false,
      orgTags: [],
      tagName: '',
      tagDesc: '',
      isCreatingObject: false,
    };
  },

  computed: {
    ...mapGetters([
      'allTasks',
      'editModes',
      'isTaskEdit',
      'organization',
      'similarSteps',
      'selectedTask',
      'isTranslation',
      'currentEditMode',
      'isStepTimeExist',
      'isPolicyAccepted',
      'showStepTimeModal',
      'isTranslationEdit',
      'showTaskParamsModal',
      'showSimilarStepsModal',
      'showRegionDefinitionModal',
      'showAssociateRegionModal',
      'showModelVersionsModal',
      'showAnnotationObjectsModal',
      'showProjectorRegionModal',
      'showUploadMediaModal',
      'negativeSteps',
      'optionalSteps',
    ]),
  },

  created() {
    this.getAllTasks();
    if ([false, 'false'].includes(this.isPolicyAccepted))
      this.showPolicyModal = true;
  },

  methods: {
    ...mapActions([
      'showModal',
      'userLogout',
      'setTaskId',
      'addNewTask',
      'getAllTasks',
      'resetProcessState',
      'setProcesses',
      'updateSingleTask',
      'deleteSingleTask',
      'fetchTaskDetails',
      'cancelTranslation',
      'closeProjectorModal',
      'handleAcceptPolicy',
      'setProcessesTranslation',
      'showModal',
    ]),

    resetStates() {
      this.setProcesses([]);
      this.setProcessesTranslation([]);
      this.cancelTranslation();
    },

    openTagsModal() {
      this.orgTagsModal = true;
      this.fetchOrgTags();
    },

    async fetchOrgTags() {
      this.isLoadingTag = true;
      const res = await httpClient.get('organization/task_record/tags', false);
      this.orgTags = res;
      this.isLoadingTag = false;
    },

    async handleAddOrgTag() {
      this.isLoadingTag = true;
      const payload = {
        tag_name: this.tagName,
        descript: this.tagDesc || 'default',
        organization: this.organization,
      };
      const res = await httpClient.post(
        'organization/task_record/tags',
        payload,
        false,
        false,
        false
      );
      this.orgTags = [...this.orgTags, res];
      this.isLoadingTag = false;
      this.tagName = '';
      this.tagDesc = '';
    },
  },
};
</script>
<style>
.color-box {
  width: 20px;
  height: 10px;
  display: inline-block;
}

.ant-modal-body {
  padding: 1em;
}
</style>
