<template>
  <a-row class="h-100 px-3 d-flex justify-content-evenly" :gutter="[24, 0]">
    <a-col
      v-for="entity in config"
      :key="entity.key"
      span="8"
      class="d-flex flex-column"
    >
      <a-input-group compact class="w-100 d-flex">
        <a-input
          v-model:value="newEntity[entity.key]"
          @pressEnter="entity.createHandler(entity.key)"
        />
        <a-button
          type="primary"
          :disabled="!newEntity[entity.key]"
          @click="entity.createHandler(entity.key)"
        >
          Add {{ entity.name }}
          <template #icon>
            <plus-outlined />
          </template>
        </a-button>
      </a-input-group>

      <a-list
        class="mt-2 flex-grow-1 d-flex flex-column entity-list"
        style="height: 1px !important"
        bordered
        :data-source="listData[entity.key]"
        size="small"
      >
        <template #renderItem="{ item }">
          <a-list-item
            class="entity-list-item"
            :class="{ 'checked-list-item': item.is_3d }"
            :style="{ 'align-items': 'center' }"
          >
            <a-typography-text v-if="editingEntityId !== item.id">
              <span class="mr-2">{{ item.name }}</span>
              <span class="text-primary" @click="onClickEditEntity(item)">
                <EditOutlined
                  v-if="!isHandAndFaceObj(item.name, this.modelChoice)"
                />
              </span>
            </a-typography-text>

            <a-input-group
              compact
              v-if="editableObj && editingEntityId === item.id"
            >
              <a-input
                v-model:value="editableObj.name"
                @change="changeEntity"
                @pressEnter="entity.endHandler(entity.key, item.name)"
                style="width: calc(100% - 100px)"
              />
              <a-tooltip title="Update">
                <a-button @click="entity.endHandler(entity.key, item.name)">
                  <template #icon><EnterOutlined /></template>
                </a-button>
              </a-tooltip>
            </a-input-group>

            <template #actions v-if="entity.key === 'staticRegions'">
              <template v-if="item.is_3d">
                <a-select
                  ref="select"
                  v-model:value="item.plane_id"
                  :style="{
                    width: item.is_3d ? '8vw' : '5vw'
                  }"
                  placeholder="add plane"
                  @change="value => addPlaneToObject(value, item.id)"
                >
                  <a-select-option
                    v-for="plane in listData.planeRegions"
                    :value="plane.id"
                    >{{ plane.name }}</a-select-option
                  >
                </a-select>

                <div style="display: flex; width: 8vw">
                  <a-typography-text
                    class="d-flex"
                    v-if="planeThresholdEditEntity?.id !== item.id"
                  >
                    <span>{{ item.plane_threshold }}</span>
                    <span
                      class="text-primary mx-1"
                      @click="planeThresholdEditEntity = { ...item }"
                    >
                      <EditOutlined />
                    </span>
                    <text>cm</text>
                  </a-typography-text>

                  <a-input
                    v-if="planeThresholdEditEntity?.id === item.id"
                    v-model:value="planeThresholdEditEntity.plane_threshold"
                    @pressEnter="changeThreshold(item)"
                  >
                    <template #addonAfter>
                      <a-tooltip title="Update">
                        <span
                          @click="changeThreshold(item)"
                          class="text-secondary"
                        >
                          <EnterOutlined />
                        </span>
                      </a-tooltip>
                    </template>
                  </a-input>
                </div>
              </template>

              <a-checkbox
                v-if="listData.planeRegions.length > 0"
                v-model:checked="item.is_3d"
                @change="event => setObject3D(event, item)"
                >3D
              </a-checkbox>

              <a-popconfirm
                cancel-text="No"
                ok-text="Yes"
                title="Are you sure you want to delete this object?"
                @confirm="entity.deleteHandle(item.id)"
              >
                <delete-outlined
                  v-if="!isHandAndFaceObj(item.name, this.modelChoice)"
                  style="padding-top: 0.6vh"
                  class="text-danger clickable"
                />
              </a-popconfirm>
            </template>

            <template #actions v-else>
              <a-space>
                <input
                  v-if="!isHandAndFaceObj(item.name, this.modelChoice)"
                  type="color"
                  v-model="item.outline_color"
                  @change="handleChangeObjectColor(item)"
                  @keyup="e => handlePressEnter(e, item)"
                />
                <a-popconfirm
                  cancel-text="No"
                  ok-text="Yes"
                  title="Are you sure you want to delete this object?"
                  @confirm="entity.deleteHandle(item.id)"
                >
                  <delete-outlined
                    v-if="!isHandAndFaceObj(item.name, this.modelChoice)"
                    style="padding-top: 0.6vh"
                    class="text-danger clickable"
                  />
                </a-popconfirm>
              </a-space>
            </template>
          </a-list-item>
        </template>
        <template #header>
          <span>{{ entity.name }}s</span>
        </template>
      </a-list>
    </a-col>
    <a-col span="6" class="d-flex flex-column">
      <a-input-group compact class="w-100 d-flex">
        <a-input
          v-model:value="newEntity.planeRegions"
          @pressEnter="handleAddPlane()"
        />
        <a-button
          type="primary"
          :disabled="!newEntity.planeRegions"
          :loading="planeLoading"
          @click="handleAddPlane()"
        >
          Add {{ 'Planes' }}
          <template #icon>
            <plus-outlined />
          </template>
        </a-button>
      </a-input-group>

      <a-list
        class="mt-2 flex-grow-1 d-flex flex-column entity-list"
        style="height: 1px !important"
        bordered
        :data-source="listData.planeRegions"
        size="small"
      >
        <template #renderItem="{ item }">
          <a-list-item class="entity-list-item">
            <a-typography-text v-if="editingEntityId !== item.id">
              <span class="mr-2">{{ item.name }}</span>
              <span class="text-primary" @click="onClickPlaneEditEntity(item)">
                <EditOutlined
                  v-if="!isHandAndFaceObj(item.name, this.modelChoice)"
                />
              </span>
            </a-typography-text>
            <a-input-group
              compact
              v-if="editableObj && editingEntityId === item.id"
            >
              <a-input
                v-model:value="editableObj.name"
                @change="changeEntity"
                @pressEnter="handleUpdatePlaneEntity(item.name)"
                style="width: calc(100% - 100px)"
              />
              <a-tooltip title="Update">
                <a-button
                  @click="handleUpdatePlaneEntity(item.name)"
                  :loading="updatePlaneLoading"
                >
                  <template #icon><EnterOutlined /></template>
                </a-button>
              </a-tooltip>
            </a-input-group>
            <template
              #actions
              v-if="!isHandAndFaceObj(item.name, this.modelChoice)"
            >
              <a-popconfirm
                cancel-text="No"
                ok-text="Yes"
                title="Are you sure you want to delete this plane?"
                @confirm="handleDeletePlaneObject(item.id)"
              >
                <delete-outlined class="text-danger clickable" />
              </a-popconfirm>
            </template>
          </a-list-item>
        </template>
        <template #header>
          <span>{{ 'Planes' }}</span>
        </template>
      </a-list>
    </a-col>
  </a-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  EnterOutlined
} from '@ant-design/icons-vue';
import { deepClone } from 'src/utils/task';
import { isHandAndFaceObj } from 'src/utils/detector';

export default {
  components: {
    DeleteOutlined,
    PlusOutlined,
    EditOutlined,
    EnterOutlined
  },
  inject: ['toast'],
  setup() {
    return {
      isHandAndFaceObj
    };
  },
  props: ['pipelineStep', 'nextStep', 'handleDone'],
  data() {
    return {
      planeLoading: false,
      updatePlaneLoading: false,
      editingEntityId: null,
      listData: {
        objects: [],
        staticRegions: [],
        planeRegions: []
      },
      default_objs: ['hands', 'faces'],
      newEntity: {
        objects: '',
        staticRegions: '',
        planeRegions: ''
      },
      // for renaming
      editableObj: { name: null },
      oldEntity: null,
      oldPlaneEntity: null,
      planeThresholdEditEntity: null,
      darkRedColor: '#ff0000'
    };
  },
  inject: ['toast'],
  computed: {
    ...mapGetters([
      'taskObjects',
      'selectedTask',
      'planeObjectsList',
      'isTask3d',
      'modelChoice'
    ]),

    config() {
      return [
        {
          name: 'Object',
          key: 'objects',
          createHandler: this.handleAddEntity,
          endHandler: this.handleUpdateEntity,
          deleteHandle: this.handleDeleteEntity
        },
        {
          name: 'Static Region',
          key: 'staticRegions',
          createHandler: this.handleAddEntity,
          endHandler: this.handleUpdateEntity,
          deleteHandle: this.handleDeleteEntity
        }
      ];
    }
  },

  watch: {
    async taskObjects() {
      await this.updateTaskObjectsAndStaticRegions();
    }
  },

  async mounted() {
    await this.addDefaultObjectsForModelAD();

    await this.updateTaskObjectsAndStaticRegions();
    await this.getTaskPlaneObjectsList();
    this.setPrevGroups({ labels: {}, rectangles: {} });
    this.setPrevPolygons({ labels: {}, lines: {} });
    this.listData.planeRegions = this.planeObjectsList;
  },
  methods: {
    ...mapActions([
      'addTaskObject',
      'updateTaskObject',
      'deleteTaskObject',
      'getTaskPlaneObjectsList',
      'createNewPlane',
      'addPlaneObject',
      'deletePlaneObject',
      'getTaskObjects',
      'setPrevGroups',
      'setPrevPolygons',
      'fetchTaskDetails'
    ]),

    async handleChangeObjectColor(object) {
      await this.updateTaskObject({
        objectId: object.id,
        objectData: { ...object }
      });
    },

    async handlePressEnter(e, object) {
      if (e.keyCode === 13) await this.handleChangeObjectColor(object);
    },

    setOldEntity() {
      this.oldEntity = this.taskObjects.find(
        obj => obj.id === this.editingEntityId
      );
    },

    setOldPlainEntity() {
      this.oldPlaneEntity = this.planeObjectsList.find(
        o => o.id === this.editingEntityId
      );
    },

    changeEntity(e) {
      const updateValue = e.target.value;
      if (!this.editingEntityId) return;
      if (this.isHandAndFaceObj(updateValue, this.modelChoice)) {
        this.oldEntity = null;
        this.oldPlaneEntity = null;
        this.editingEntityId = null;
        this.editableObj = { name: null };
      }
    },

    onClickEditEntity(item) {
      this.editingEntityId = item.id;
      this.setOldEntity();
      this.editableObj = { ...item };
    },

    onClickPlaneEditEntity(item) {
      this.editingEntityId = item.id;
      this.setOldPlainEntity();
      this.editableObj = { ...item };
    },

    isEntityExists(key) {
      const entityExist = this.listData[key].some(
        obj => obj.name.toLowerCase() === this.newEntity[key].toLowerCase()
      );
      if (entityExist) {
        this.toast.error('You can not create duplicate objects!');
        return true;
      }
      return false;
    },

    isAddingDefaultObjects(key) {
      if (this.isHandAndFaceObj(this.newEntity[key], this.modelChoice)) {
        this.toast.error('You can not add default objects!');
        return true;
      }
      return false;
    },

    isValidPattern(value) {
      const pattern = new RegExp(
        '^(?:[A-Za-z]+(?: [A-Za-z]+)*(?:[\\s]*[A-Za-z0-9]+)?)$'
      );
      if (pattern.test(value)) return true;
      return false;
    },

    async setObject3D(event, item) {
      const listOfStaticRegions = [...this.listData.staticRegions];
      if (event.target.checked == false && item.plane_id) {
        const updatedObject = listOfStaticRegions.find(
          object => object.id == item.id
        );
        let payload = {
          ...updatedObject,
          is_3d: false,
          plane_id: null
        };
        await this.updateTaskObject({
          objectId: item.id,
          objectData: payload
        });
      } else {
        this.listData['staticRegions'] = listOfStaticRegions.map(region =>
          region.is_3d && region.plane_id
            ? region
            : region.id === item.id
            ? { ...region, is_3d: event.target.checked }
            : { ...region, is_3d: false }
        );
      }
    },

    async updateTaskObjectsAndStaticRegions() {
      const taskObjects = deepClone(this.taskObjects);
      const objects = this.sortObjects(
        taskObjects.filter(
          obj => !obj.cordinates_of_static_object && !obj.is_static
        )
      );
      const staticRegions = taskObjects.filter(obj => obj.is_static);
      this.updateObjectOutlineColor(objects);
      this.listData = {
        objects,
        staticRegions,
        planeRegions: this.planeObjectsList
      };
    },

    updateObjectOutlineColor(objects) {
      objects.forEach(item => {
        if (isHandAndFaceObj(item.name, this.modelChoice)) return;
        item.outline_color = item.outline_color ?? this.darkRedColor;
      });
    },

    sortObjects(objects) {
      //place hands and faces at the end of list
      const priority = {
        hands: 1,
        faces: -1
      };

      objects.sort(function(a, b) {
        if (
          priority.hasOwnProperty(a.name) &&
          priority.hasOwnProperty(b.name)
        ) {
          return priority[b.name];
        }
        if (priority.hasOwnProperty(a.name)) {
          return -1;
        }
      });
      return objects;
    },

    async addDefaultObjectsForModelAD() {
      let is_new_obj_added = false;
      if (
        this.modelChoice !== 'Model ADF' &&
        !this.objectExistInTaskObjects(this.default_objs[0])
      ) {
        await this.addObject(this.default_objs[0]);
      }

      if (!this.objectExistInTaskObjects(this.default_objs[1])) {
        await this.addObject(this.default_objs[1]);
      }
      if (this.modelChoice === 'Model ADF') {
        let handsObject = this.taskObjects.find(obj => obj.name === 'hands');
        if (handsObject != null) await this.deleteTaskObject(handsObject.id);
      }

      if (is_new_obj_added) {
        await this.getTaskObjects(this.selectedTask);
      }
    },

    isEntityDisabled(obj) {
      return this.default_objs.includes(obj);
    },

    async addObject(object_name) {
      const payload = {
        name: object_name,
        task: this.selectedTask
      };
      await this.addTaskObject(payload);
    },

    objectExistInTaskObjects(object_name) {
      return this.taskObjects.some(taskObj => taskObj.name === object_name);
    },

    async changeThreshold(item) {
      let payload = {
        ...item,
        plane_threshold: this.planeThresholdEditEntity.plane_threshold
      };
      if (
        item.plane_threshold !== this.planeThresholdEditEntity.plane_threshold
      ) {
        await this.updateTaskObject({
          objectId: item.id,
          objectData: payload
        });
      }
      this.planeThresholdEditEntity = null;
    },

    handleAddEntity(key) {
      if (!this.newEntity[key]) return;
      if (this.isAddingDefaultObjects(key)) return;
      if (this.isEntityExists(key)) return;
      if (!this.isValidPattern(this.newEntity[key])) {
        this.toast.info(
          'Object name should only contains valid Alpha numeric words!'
        );
        return;
      }
      const payload = {
        name: this.newEntity[key],
        task: this.selectedTask,
        outline_color: this.darkRedColor
      };
      if (key === 'staticRegions') payload['is_static'] = true;
      this.addTaskObject(payload);
      this.newEntity[key] = '';
    },

    async addPlaneToObject(value, id) {
      const listOfObjectAndStaticRegions = [...this.listData.staticRegions];
      const updatedObject = listOfObjectAndStaticRegions.find(
        data => data.id == id
      );
      const updatedListEntity = this.listData.planeRegions.find(
        plane => plane.id == value
      );

      let payload = {
        ...updatedObject,
        is_3d: true,
        plane_id: updatedListEntity.id
      };

      await this.updateTaskObject({
        objectId: id,
        objectData: payload
      });
    },

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },

    async handleAddPlane() {
      if (!this.isTask3d) {
        this.toast.error('Defined task is not 3d!');

        return;
      }
      const key = 'planeRegions';
      if (this.isAddingDefaultObjects(key)) return;
      if (this.isEntityExists(key)) return;
      const name = this.newEntity.planeRegions;
      if (!this.isValidPattern(name)) {
        this.toast.info(
          'Plain name should only contains valid Alpha numeric words!'
        );
        return;
      }
      this.planeLoading = true;
      await this.createNewPlane({
        task: this.selectedTask,
        points: JSON.stringify({
          plane_points: [
            [this.getRandomInt(160, 480), this.getRandomInt(120, 360)],
            [this.getRandomInt(160, 480), this.getRandomInt(120, 360)],
            [this.getRandomInt(160, 480), this.getRandomInt(120, 360)]
          ]
        }),
        type: 'Workbench',
        tolerance: 10.0,
        name: name
      });
      await this.getTaskPlaneObjectsList();
      this.listData.planeRegions = this.planeObjectsList;
      this.newEntity['planeRegions'] = '';
      this.planeLoading = false;
    },

    async handleUpdateEntity(key, updatedName) {
      if (!this.editingEntityId) return;
      const oldEntity = this.taskObjects.find(
        obj => obj.id === this.editingEntityId
      );
      if (!this.isValidPattern(this.editableObj.name)) {
        this.toast.info(
          'Object name should only contains valid Alpha numeric words!'
        );
        return;
      }
      if (this.editableObj.name !== oldEntity.name) {
        await this.updateTaskObject({
          objectId: oldEntity.id,
          objectData: { ...this.editableObj }
        });
      }
      this.editingEntityId = null;
      this.oldEntity = null;
      this.editableObj = { name: null };
    },

    async handleUpdatePlaneEntity(updatedName) {
      if (!this.editingEntityId) return;
      if (!this.isValidPattern(this.editableObj.name)) {
        this.toast.info(
          'Plane name should only contains valid Alpha numeric words!'
        );
        return;
      }
      this.updatePlaneLoading = true;
      const updatedEntity = this.editableObj;
      await this.addPlaneObject(updatedEntity);
      await this.getTaskPlaneObjectsList();
      this.listData.planeRegions = this.planeObjectsList;
      this.oldPlaneEntity = null;
      this.editingEntityId = null;
      this.editableObj = { name: null };
      this.updatePlaneLoading = false;
    },

    async handleDeleteEntity(id) {
      await this.deleteTaskObject(id);
      await this.fetchTaskDetails(this.selectedTask);
      await this.getTaskObjects(this.selectedTask);
    },

    async handleDeletePlaneObject(id) {
      await this.deletePlaneObject(id);
      await this.getTaskPlaneObjectsList();
      this.listData.planeRegions = this.planeObjectsList;
    }
  }
};
</script>
<style>
.entity-list > .ant-list-header {
  background: lightgray !important;
  font-weight: 600 !important;
}

.entity-list > .ant-spin-nested-loading {
  overflow-y: auto;
}

.entity-list-item:hover {
  background: rgb(231, 231, 231);
}

.entity-list-item ul.ant-list-item-action {
  margin-left: 0;
}

.checked-list-item.ant-list-item {
  display: block;
}

.checked-list-item ul.ant-list-item-action {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}
</style>
