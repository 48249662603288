<template>
  <HeatMap
    v-if="show"
    :options="heatMapConfig"
    :show-close-btn="true"
    @close="show = false"
  />

  <a-table
    v-else
    bordered
    :data-source="sortedModelVersionList"
    :columns="columns"
    :scroll="{
      y: '40vh',
      x: true,
    }"
    :pagination="false"
  >
    <template #emptyText>
      <div
        id="no-models-found"
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <laptop-outlined style="font-size: 40px" />
        <span class="mt-2">No Models Found</span>
      </div>
    </template>

    <template #bodyCell="{ column, text, record, index }">
      <template v-if="column.dataIndex === 'description'">
        <div class="editable-cell">
          <div
            v-if="editableData[record.id]"
            class="editable-cell-input-wrapper"
          >
            <a-input-group class="description-edit-input">
              <a-textarea
                v-model:value="editableData[record.id].description"
                allow-clear
              />
              <a-button
                style="width: 44px; height: 54px"
                @click="cancelEdit(record.id)"
              >
                <template #icon>
                  <close-circle-outlined />
                </template>
              </a-button>
            </a-input-group>
          </div>
          <div v-else>
            {{ text || ' ' }}
          </div>
        </div>
      </template>

      <template v-if="column.dataIndex === 'date'">
        {{ text ? dateHelper.formatDate(text) : '-' }}
      </template>

      <template v-if="column.dataIndex === 'id'">
        <a-radio
          v-model:checked="modelButtonDict[text]"
          class="radioBtn"
          :disabled="isEdit"
          @change="handleChangeModel(text)"
        />
      </template>

      <template v-if="column.dataIndex === 'action'">
        <div v-if="editableData[record.id] || isNewSelectedModal(record.id)">
          <a-button
            :loading="isUpdatingModelVersion || isUpdatingAssociateModelVersion"
            type="primary"
            class="mr-1"
            @click="saveRecord(record.id)"
          >
            <template #icon>
              <check-outlined />
            </template>
            Save
          </a-button>
          <a-button
            v-if="isDefaultModelChange"
            @click="cancelSelectDefaultModel"
          >
            Cancel
          </a-button>
          <!-- v-if="editableData[record.id] || isNewSelectedModal(record.id)" -->
        </div>

        <div v-else>
          <!-- <a-button
            type="primary"
            class="editable-cell-icon"
            :disabled="isEmptyDict(index)"
            @click="showMatrix(index)"
          >
            Eval.
          </a-button> -->
          <a-button
            type="primary"
            class="editable-cell-icon"
            :disabled="isEdit || isDefaultModelChange"
            @click="editRecord(record.id)"
          >
            <template #icon>
              <edit-outlined />
            </template>
          </a-button>
          <a-popconfirm
            title="Are you sure you want to delete this model version?"
            ok-text="Yes"
            cancel-text="No"
            :disabled="selectedModelVersion?.id === record.id"
            @confirm="toggleDelete(record)"
          >
            <a-button
              danger
              class="editable-cell-icon"
              :disabled="selectedModelVersion?.id === record.id"
            >
              <template #icon>
                <delete-outlined />
              </template>
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dateHelper from '../../../../shared/Helpers/dateHelper';
import {
  CloseCircleOutlined,
  CheckOutlined,
  LaptopOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons-vue';
import { userModelVersionTable as columns } from '@/config/model-version-config';
import HeatMap from '../../model-versions/wandb_chart/Heatmap.vue';
import wandbJson from '../../model-versions/wandb_history';
import { heatMap } from '@/config/charts-config.js';
import dayjs from 'dayjs';

export default {
  components: {
    CloseCircleOutlined,
    CheckOutlined,
    EditOutlined,
    DeleteOutlined,
    LaptopOutlined,

    HeatMap,
  },
  inject: ['toast'],
  setup() {
    return { columns, dateHelper };
  },

  data() {
    return {
      editableData: {},
      show: false,
      heatMapConfig: {
        ...heatMap,
        confusion_matrix: wandbJson.confusion_matrix,
      },
      modelButtonDict: {},
    };
  },

  computed: {
    ...mapGetters([
      'selectedTask',
      'modelVersionsList',
      'isUpdatingModelVersion',
      'taskAssociatedModelVersion',
      'isUpdatingAssociateModelVersion',
    ]),

    isEdit() {
      return Object.keys(this.editableData).length !== 0;
    },

    selectedModelVersion() {
      if (!this.taskAssociatedModelVersion) return;
      return this.modelVersionsList.find(
        (model) => model.id === this.taskAssociatedModelVersion
      );
    },

    isDefaultModelChange() {
      const currSelected = Object.keys(this.modelButtonDict)?.find(
        (id) => this.modelButtonDict[id] === true
      );
      return Number(currSelected) !== this.taskAssociatedModelVersion;
    },

    sortedModelVersionList() {
      if (!this.modelVersionsList.length) return [];
      const sorted = this.modelVersionsList
        .filter((mv) => !mv.is_deleted)
        .sort((a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1));
      return sorted;
    },
  },

  watch: {
    taskAssociatedModelVersion(selectedModel) {
      this.handleChangeModel(selectedModel);
    },
  },

  mounted() {
    // if (!this.selectedTask && this.modelVersionsList?.length) {
    //   this.clearModelVersionsList();
    // }
    if (this.taskAssociatedModelVersion) {
      this.handleChangeModel(this.taskAssociatedModelVersion);
    }
  },

  methods: {
    ...mapActions([
      'updateModelVersion',
      'toggleDelete',
      'clearModelVersionsList',
      'showModal',
      'updateTaskModelVersion',
    ]),

    editRecord(recordId) {
      const editRecord = this.modelVersionsList.filter(
        (item) => item.id === recordId
      )[0];
      this.editableData = {
        ...this.editableData,
        [recordId]: { ...editRecord },
      };
    },

    async saveRecord(recordId) {
      const editedRecord = this.editableData[recordId];

      if (editedRecord) {
        if(editedRecord.description.length < 50) {
          this.toast.error('The description must be at least 50 characters long.');
          return
        }
        if (!editedRecord.description)
          return this.toast.error('Version Description cannot be empty!');
        const data = {
          modelVersionId: editedRecord.id,
          payload: {
            description: editedRecord.description,
          },
        };
        const res = await this.updateModelVersion(data);
        if (!res) {
          this.toast.error('Error occurred in updating model version!');
        }
        delete this.editableData[recordId];
      } else if (this.isDefaultModelChange) {
        this.handleSaveSelectedModel();
      }
    },

    cancelEdit(recordId) {
      delete this.editableData[recordId];
    },

    handleChangeModel(modelId = null) {
      let temp = { ...this.modelButtonDict };
      this.modelVersionsList.forEach(({ id }) => {
        modelId && Number(id) === Number(modelId)
          ? (temp[id] = true)
          : (temp[id] = false);
      });
      this.modelButtonDict = { ...temp };
    },

    handleSaveSelectedModel() {
      const modelVersionId = Object.keys(this.modelButtonDict).filter(
        (modelId) => this.modelButtonDict[modelId]
      )[0];
      const modelVersion = this.modelVersionsList.find(
        (mv) => mv.id === Number(modelVersionId)
      );

      this.updateTaskModelVersion(modelVersion);
    },

    isNewSelectedModal(modelId) {
      const res =
        this.modelButtonDict[modelId] === true &&
        modelId !== this.taskAssociatedModelVersion;
      return res;
    },

    cancelSelectDefaultModel() {
      const temp = Object.assign({}, this.modelButtonDict);
      Object.keys(temp).forEach((key) => {
        Number(key) === this.taskAssociatedModelVersion
          ? (temp[key] = true)
          : (temp[key] = false);
      });
      this.modelButtonDict = temp;
    },

    isEmptyDict(index) {
      const dict = this.modelVersionsList[index].wandb_history;
      return dict === null || Object.keys(dict).length === 0;
    },

    showMatrix(index) {
      const wandb_history = this.modelVersionsList[index]?.wandb_history;
      if (!wandb_history) return;
      const cols = wandbJson.confusion_matrix[0].length;
      this.heatMapConfig['labels'] = Array.from(
        { length: cols },
        (_, i) => `Class${i + 1}`
      );
      this.show = true;
    },
  },
};
</script>
<style>
.editable-cell-text-wrapper,
.editable-cell-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.description-edit-input {
  width: 90%;
  display: flex;
}

.editable-cell-icon {
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
