<template>
  <a-modal
    v-model:visible="showStreamModal"
    :footer="null"
    width="100%"
    wrap-class-name="full-modal"
    destroy-on-close
    :title="displayName"
    :get-popup-container="() => document.getElementById('stream-modal-body')"
    @cancel="handleStreamModalClose"
  >
    <div id="stream-modal-body" class="w-100">
      <WebRtc
        :serial-number="serialNumber"
        :task-list="taskOptions"
        :device-setting-url="deviceSettingUrlProjector"
        :model-id="serialNumber"
        :task-name="taskName"
        :current-task="currentTask"
        :projector="projectorStatus"
        :ice-options="iceOptions"
      />
    </div>
  </a-modal>

  <a-table
    id="dep-model-active-devices-table"
    :columns="columns"
    :data-source="activeDevices"
    :loading="activeDevices.length <= 0"
    :scroll="{ x: true }"
    :pagination="{ position: ['bottomCenter'] }"
  >
    <template #title>
      <a-typography-title :level="5">
        Device(s) Active: &nbsp;
        <a-tag id="record-active-devices-count" color="blue">
          {{ activeDevices.length }}
        </a-tag>
      </a-typography-title>
    </template>

    <template #emptyText>
      <div
        id="dep-model-no-devices"
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <laptop-outlined style="fontsize: 40px" />
        <span class="mt-2"
          >No Active Device Found. Looking for active device(s).</span
        >
      </div>
    </template>

    <template :id="'dep-model' + title + '-hdcell'" #headerCell="{ title }">
      <span class="text-center">
        {{ title }}
      </span>
    </template>

    <template #bodyCell="{ record, column, index }">
      <div
        v-if="column.dataIndex === 'deviceId'"
        :id="'dep-model-deviceId-' + index"
        class="text-center"
      >
        {{ record.display_name || record.Serial_number }}
      </div>

      <div v-if="column.dataIndex === 'status'" class="text-center">
        <!-- Inference -->
        <a-tag
          v-if="
            record.isInferenceRunning ||
              (record.is_shift_managed && record.inference)
          "
          :id="'dm-status-inference-' + index"
          color="processing"
        >
          Running Inference
        </a-tag>

        <!-- Uploading -->
        <div v-else-if="record?.uploading">
          <a-tag
            :id="'dep-device-' + index + '-uploading-status'"
            color="processing"
          >
            <template #icon>
              <cloud-upload-outlined />
            </template>
            Uploading
          </a-tag>
          <a-tag v-if="record?.uploading?.name" color="success">
            {{ record?.uploading?.name }}
          </a-tag>
          <a-progress
            :id="'dep-device-' + index + '-uploading-progress'"
            type="circle"
            :percent="record?.uploading?.progress"
            :width="40"
          />
        </div>

        <!-- Downloading -->
        <div v-else-if="record?.downloading">
          <a-tag
            :id="'dm-status-downloading-button-' + index"
            color="processing"
          >
            <template #icon>
              <cloud-upload-outlined />
            </template>
            Downloading
          </a-tag>
          <a-tag :id="'dm-status-downloaded-button-' + index" color="success">
            {{ record?.downloading.name }}
          </a-tag>

          <a-progress
            :id="'dm-download-progress-button-' + index"
            type="circle"
            :percent="record?.downloading?.progress"
            :width="40"
          />
        </div>

        <a-tag v-else :id="'dep-model-status-none-' + index">
          None
        </a-tag>
      </div>

      <div v-if="column.dataIndex === 'action'" class="text-center">
        <a-space>
          <a-button
            class="my-1"
            type="primary"
            block
            @click="showStream(record)"
            :id="'dep-model-task-schedule-button-' + index"
          >
            Setup Projector
          </a-button>
          <a-select
            v-show="Array.isArray(screens[record.Serial_number]) && screens[record.Serial_number].length > 0"
            v-model:value="selectedScreen[record.Serial_number]"
            style="width: 250px"
            :options="
              Array.isArray(screens[record.Serial_number])
                ? screens[record.Serial_number].map((screen) => ({
                    value: screen,
                  }))
                : []
            "
            @select="selectScreen(record)"
          />
          <a-button
            class="my-1"
            type="primary"
            block
            :loading="isLoadingRefreshScreens[index]"
            @click="sendSQSMessageForRefreshScreens(record, index)"
          >
            Refresh Screens
          </a-button>
        </a-space>
        <!-- <a-space>
          <a-button
            class="my-1"
            type="primary"
            block
            @click="showStream(record)"
            :id="'dep-model-task-schedule-button-' + index"
          >
            Setup Projector
          </a-button>
          <a-select
            v-show="screens[record.Serial_number]"
            v-model:value="selectedScreen[record.Serial_number]"
            style="width: 250px"
            :options="
              screens[record.Serial_number]
                ? screens[record.Serial_number].map((screen) => {
                    value: screen,
                }))
                : []
            "
            @select="selectScreen(record)"
          />
        </a-space> -->
        <!-- <a-space
          :id="'projector-switch-' + index"
        >
          <a-switch
          :checked="devicesSettingsDict[record.Serial_number]?.projector_enable"
          size="large"
          @change="updateProjectorStatus(record)"
        />
          <span>Projector</span>
        </a-space> -->
      </div>
      <!-- <div
        v-if="column.dataIndex === 'task'"
        :id="'dep-model-task-' + index"
        class="text-center"
      >
        <a-select
          :id="'dep-model-select-device-task-' + index"
          :ref="(el) => (taskInputRefs[record.Serial_number] = el)"
          show-search
          class="w-100"
          :value="record.Task"
          placeholder="Select Operation"
          :options="taskOptions"
          :loading="isLoadingTasks"
          :disabled="record?.isInferenceRunning"
          :filter-option="filterOption"
          @change="(val) => handleChangeTask(record, val)"
        />
      </div> -->

      <!-- <div class="text-center" v-if="column.dataIndex === 'action'">
        <a-button
          :id="'dep-model-task-record-button-' + index"
          :disabled="!record.Task"
          block
          type="primary"
          @click="handleInference(record)"
        >
          {{ record?.isInferenceRunning ? 'Stop' : 'Start' }}
          Task
        </a-button>

        <a-button
          :id="'dep-model-task-schedule-button-' + index"
          class="my-1"
          type="primary"
          block
          @click="showScheduleModal(record, 'inference')"
        >
          Schedule Task
        </a-button>

        <a-space
          :id="'dep-model-trace-switch-' + index"
          class="clickable"
          @click="handleTrace(record)"
        >
          <a-switch :checked="record?.recordInference" />
          <span>Trace</span>
        </a-space>
      </div> -->
    </template>
  </a-table>
</template>
<script>
import { inject, ref, watch } from 'vue';

import {
  CloudUploadOutlined,
  LaptopOutlined,
  CloseOutlined
} from '@ant-design/icons-vue';
import spaceMixin from 'src/mixins/handleSpace';
import daysOptions from 'src/config/days-config.js';
import columns from 'src/config/iot-pulgins-config';
import { useActiveDevices } from '../../RecordData/composables/useActiveDevices';
import { useDeviceSettings } from '../../RecordData/composables/useDeviceSettings';
import DeviceService from '@/services/device';

import WebRtc from '../../devices/WebRtc.vue';
import fcm from 'src/services/fcm';

export default {
  components: {
    LaptopOutlined,
    CloudUploadOutlined,
    CloseOutlined,
    WebRtc
  },
  mixins: [spaceMixin],
  props: ['activeDevices'],
  setup() {
    const screens = ref([]);
    const selectedScreen = ref([]);
    const toast = inject('toast');
    const organization = localStorage.getItem('organization');
    const {
      activeDevicesList,
      scheduleInference,
      isScheduling,
      selectedDays,
      startEndTime,
      isLoadingDevices,
      handleRecording,
      showSchedule,
      taskInputRefs,
      taskOptions,
      handleInference
    } = useActiveDevices();

    const {
      devicesSettingsDict,
      devicesStatusDict,
      projectorStatus,
      taskName,
      deviceSettingUrlProjector,
      rtcServerThreadStatus,
      serialNumber,
      iceOptions,
      displayName,
      streamSignal,
      showStreamModal,
      showScheduleModal,
      handleChangeTask,
      updateDeviceSettings,
      getActiveDeviceSettings,
      updateDeviceSettingRecord,
      showStreamingModal
    } = useDeviceSettings(toast);

    // watch(
    //   () => this.activeDevices,
    //   (dict, _) => {
    //     if (!Object.keys(dict).length) return;
    //     Object.entries(dict).forEach(([key, value]) => {
    //       console.log('value', value);
    //       if (value.total_projector_screens) {
    //         screens.value[value?.Serial_number] = value.total_projector_screens;
    //       }
    //       if (value.current_projector_screen) {
    //         selectedScreen.value[value?.Serial_number] =
    //           value.current_projector_screen;
    //       }
    //       // if (value.downloading) {
    //       //   const { name } = value.downloading;
    //       //   if (name.endsWith('.zip') && !isModelToastShown.value) {
    //       //     isModelToastShown.value = true;
    //       //     toast.info(
    //       //       'We are downloading the model on your device. This will take several minutes.'
    //       //     );
    //       //   }
    //       // }
    //     });
    //   }
    // );

    function handleTrace(record) {
      const { recordInference } = record;
      updateDeviceSettings({
        ...record,
        key: 'recordInference',
        value: !recordInference
      });
    }
    function showStream(record) {
      showStreamModal.value = true;
      streamSignal.value = true;
      rtcServerThreadStatus.value = true;
      showStreamingModal(record);
      // this.streamSignalPolling = setInterval(this.sendSignalToStream, 2000);
    }

    function handleStreamModalClose() {
      showStreamModal.value = false;
    }

    async function selectScreen(record) {
      const payload = {
        current_projector_screen: selectedScreen.value[record.Serial_number]
      };
      const [error, data] = await DeviceService.updateCurrentScreen(
        record?.id,
        organization,
        payload
      );
      if (error) return toast.error('Error while changing Screen');
      toast.success('Screen Changed', {
        timeout: 2000
      });
    }

    return {
      columns,
      handleChangeTask,
      activeDevicesList,
      showScheduleModal,
      scheduleInference,
      isScheduling,
      selectedDays,
      startEndTime,
      showSchedule,
      isLoadingDevices,
      taskInputRefs,
      updateDeviceSettings,
      handleRecording,
      daysOptions,
      taskOptions,
      handleTrace,
      handleInference,
      showStreamingModal,
      showStreamModal,
      rtcServerThreadStatus,
      serialNumber,
      iceOptions,
      deviceSettingUrlProjector,
      taskName,
      projectorStatus,
      devicesSettingsDict,
      devicesStatusDict,
      displayName,
      getActiveDeviceSettings,
      updateDeviceSettingRecord,
      showStream,
      handleStreamModalClose,
      screens,
      selectScreen,
      selectedScreen,
      organization
    };
  },
  watch: {
    activeDevices(dict) {
      if (!Object.keys(dict).length) return;
      Object.entries(dict).forEach(([key, value]) => {
        console.log('value', value);
        if (value.total_projector_screens) {
          this.screens[value?.Serial_number] = value.total_projector_screens;
        }
        if (value.current_projector_screen) {
          this.selectedScreen[value?.Serial_number] =
            value.current_projector_screen;
        }
      });
    }
  },
  data(){
    return{
      isLoadingRefreshScreens: []
    }
  },
  methods:{
    async sendSQSMessageForRefreshScreens(record, index){
      this.isLoadingRefreshScreens[index] = true;
      const payload = {
        data: { dummyData: '' },
        type: 'refresh-projector-screens',
        device_id: record.Serial_number,
      };
      const [error] = await fcm.sendMessageToSQS(payload, false);
      this.isLoadingRefreshScreens[index] = false;
    }
  }
};
</script>
<style scoped></style>
