<template>
  <a-card
    :id="'object-image-' + index"
    size="small"
    hoverable
    class="image-card"
    style="width: 300px"
  >
    <a-card-meta>
      <template #title>
        <div class="w-100 d-flex" style="font-weight: 400 !important">
          {{ getFileName(imageObj.path_to_image) }}
        </div>
      </template>
    </a-card-meta>

    <template #cover>
      <div class="w-100 d-flex" style="height: 200px" v-if="urlLoading">
        <a-spin class="m-auto" tip="Loading Image..." />
      </div>
      <img
        alt=""
        :src="imageObj.url ? imageObj.url : null"
        style="width: 100%"
      />
    </template>

    <template #actions>
      <div class="d-flex justify-content-center">
        <a-tooltip title="Annotate">
          <a-button
            type="default"
            :id="'video-img-edit-btn-' + index"
            :class="{ disabled: urlLoading }"
            style="flex: 1; border: none; box-shadow: none"
            @click="openAnnotationModal(imageObj)"
          >
            <template #icon>
              <edit-outlined class="text-primary" />
            </template>
          </a-button>
        </a-tooltip>
        <a-divider type="vertical" style="height: 30px" />
        <div class="w-50 d-flex align-items-center">
          <a-checkbox
            class="circle-checkbox"
            style="flex: 1; justify-content: center"
            v-model:checked="selectedItem"
            @change="handleSelectAllChange"
          />
        </div>
      </div>
    </template>
  </a-card>
</template>
<script>
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons-vue';
import { mapGetters } from 'vuex';
import VideoService from 'src/services/videos';
export default {
  components: {
    EditOutlined,
    DeleteOutlined,
    CheckCircleOutlined,
  },
  props: {
    index: Number,
    imageObj: Object,
    presignedUrl: Object,
    getFileName: Function,
    openAnnotationModal: Function,
    deleteAnnotation: Function,
    selectAll: Object,
  },
  emits: ['updateSelectAll'],
  data() {
    return {
      selectedItem: false,
    };
  },
  mounted() {
    this.selectedItem = this.selectAll[this.imageObj?.id] || false;
  },
  computed: {
    ...mapGetters(['organization']),
    urlLoading() {
      return this.imageObj?.url ? false : true;
    },
  },
  watch: {
    selectAll: {
      deep: true,
      handler(value) {
        this.selectedItem = value[this.imageObj?.id] || false;
      },
    },
  },
  methods: {
    handleSelectAllChange(event) {
      this.$emit('updateSelectAll', {
        id: this.imageObj.id,
        selected: this.selectedItem,
      });
    },
  },
};
</script>
<style>
.image-card {
  padding: 1em;
  padding-bottom: 0;
}

.image-card .ant-card-meta-title {
  white-space: initial;
  text-align: center;
}
.circle-checkbox .ant-checkbox-inner {
  border-radius: 50%;
}

.circle-checkbox .ant-checkbox-inner::after {
  border-radius: 50%;
}
</style>
